exports.onRouteUpdate = ({ location }) => {
  // hide all menus when page changes
  // let megaMenu = document.querySelector('.megaMenu');
  // if (megaMenu.style.visibility === 'visible') {
  //   megaMenu.style.visibility = 'hidden';
  //   megaMenu.style.opacity = '0';
  // }
  //
  // let mobileMenu = document.querySelector('.mobileMenu');
  // let barsEl = document.getElementById('faBars');
  // let timesEl = document.getElementById('faTimes');
  //
  // if (mobileMenu.style.visibility === 'visible') {
  //   mobileMenu.style.visibility = 'hidden';
  //   mobileMenu.style.opacity = '0';
  //   timesEl.style.display = 'none';
  //   barsEl.style.display = 'inline-block';
  // }
}
